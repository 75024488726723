<template>
  <div class="v-stack gap-3 h-stretch">
    <div class="card light">
      <div class="content h-stretch">
        <div class="v-stack gap-3 h-stretch">
          <template v-if="$store.state.accessLevel > 9">
            <label class="text-left">User</label>
            <select v-model="loan.user">
              <option v-for="user in users" :key="user._id" :value="user._id">
                {{ user.fullName }}
              </option>
            </select>
          </template>
          <a class="heading-title-3">Prediction</a>
          <label class="text-left">Predicted last payment</label>
          <div class="text-left">
            {{ formatDate(predictedEndDate, "DD.MM.YYYY") }}
          </div>
          <label class="text-left">Predicted loan length</label>
          <div class="text-left">{{ predictedDuration || 0 }} months</div>
          <a class="heading-title-3">Loan</a>
          <label class="text-left required">Start Date</label>
          <InputDatePicker v-model="loan.startDate"></InputDatePicker>
          <label class="text-left required">Amount</label>
          <input v-model="loan.amount" type="number" />
          <label class="text-left required">Monthly</label>
          <input v-model="loan.monthlyRate" type="number" />
          <label class="text-left required">Description</label>
          <textarea
            placeholder="Please describe the loan"
            v-model="loan.description"
          ></textarea>
          <div class="text-center">
            The monthly fee will be withdrawn from your account on every 15th
            day of the month until the debt is paid, starting next month after
            the start date.
          </div>
          <div class="h-stack h-end">
            <button class="submit" @click="submit()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { loanObject } from "@/utils.js";
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  data() {
    return {
      users: [],
      loan: {
        startDate: moment().toISOString(),
        amount: 0,
        monthlyRate: 0,
        onetimepay: [],
        description: "",
        user: this.id || this.$store.state.id,
      },
    };
  },
  components: {
    InputDatePicker,
  },
  computed: {
    predictedEndDate() {
      return loanObject.predictedEndDate(this.loan).endDate;
    },
    predictedDuration() {
      return loanObject.predictedEndDate(this.loan).duration;
    },
  },
  props: ["id"],
  methods: {
    ...mapActions(["addLoan", "getUsers"]),
    submit() {
      if (
        this.loan.amount < 1 ||
        this.loan.monthlyRate < 1 ||
        Number(this.loan.amount) < Number(this.loan.monthlyRate)
      ) {
        return;
      }

      this.addLoan(this.loan)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate(dateString, format) {
      if (dateString) {
        const date = moment(dateString);
        return date.format(format);
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.getUsers()
      .then((users) => {
        this.users = users
          .filter((user) => user.accessLevel > 4)
          .sort((a, b) => {
            return a.fullName.localeCompare(b.fullName, "cs");
          });
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
